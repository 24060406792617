.ant-card{
    background: linear-gradient(45deg, #1e272742, #9e11db54);
/* border-color: transparent; */
/* box-shadow: 3px 3px lightslategray; */
color: white;
font-weight: 500;
}

.ant-card >div{
    background: linear-gradient(45deg, #ffffff26, #ffffff7a);
}

 /* .ant-card-hoverable{
    box-shadow: 5px 5px 5px 5px gray;
}  */

.card-gradient{
    background: linear-gradient(45deg, #1e272742, #9e11db54);
}

.big-card .ant-card-body{
    height: 195px;
    padding-top: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* background: radial-gradient(#3afdf4b0, #5427d5b3); */